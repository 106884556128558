







































































































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent } from '@ag-grid-community/core';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ClientStatusEnum } from '@/enums/crm/client-status.enum';
import RouterService from '@/services/router.service';
import ContentDialog from '@/components/content-dialog.vue';
import { DateHelper } from '@/utils/helpers/date-helper';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import ProcessService from '@/services/crm/process.service';
import ProcessFlowModel from '@/models/crm/process-flow.model';
import CrmKanbanViewer from '@/components/crm/kanban-viewer.vue';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import ProcessModel from '@/models/crm/process.model';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { ClientTypeEnum } from '@/enums/client-type.enum';

type DataGridFilterConfig = {
  keyword: string | undefined;
  flow: ProcessFlowModel[];
};

@Component({
  components: {
    DataGridFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmKanbanViewer,
  },
})
export default class CrmProcesses extends Vue {
  @inject(InjectionIdEnum.CrmProcessService)
  private processService!: ProcessService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'data', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.process.grid.code')}`,
        field: 'idProcesso',
        minWidth: 85,
        flex: 0.35,
      },
      {
        headerName: `${this.$t('crm.view.process.grid.flow')}`,
        field: 'fluxo',
        minWidth: 130,
        flex: 1.1,
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.view.process.grid.step')}`,
        field: 'etapa',
        minWidth: 130,
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.view.process.grid.date')}`,
        field: 'data',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.process.grid.queue')}`,
        field: 'fila',
        minWidth: 130,
        autoHeight: true,
        valueFormatter: (params): string => params.value || '-',
      },
      {
        headerName: `${this.$t('crm.view.process.grid.action')}`,
        field: 'acao',
        minWidth: 130,
        autoHeight: true,
        valueFormatter: (params): string => params.value || '-',
      },
      {
        headerName: `${this.$t('crm.view.process.grid.reason')}`,
        field: 'motivo',
        minWidth: 130,
        autoHeight: true,
        valueFormatter: (params): string => params.value || '-',
      },
      {
        headerName: `${this.$t('crm.view.process.grid.result')}`,
        field: 'resultado',
        minWidth: 130,
        autoHeight: true,
        valueFormatter: (params): string => params.value || '-',
      },
    ],
  };

  filters: DataGridFilterConfig = {
    keyword: undefined,
    flow: new Array<ProcessFlowModel>(),
  };

  items: ProcessModel[] = [];

  selected: ProcessModel[] = [];

  idFila = -1;

  dialogConfig: IKeyValue<IDialogConfig> = {
    processKanban: {
      show: false,
      flow: new ProcessFlowModel(),
      process: new ProcessModel(),
    },
    prospectProcesses: {
      show: false,
      process: new ProcessModel(),
    },
  };

  multipleFilterChanged = false;

  flow: ProcessFlowModel = new ProcessFlowModel();

  inputFlow = '';

  processFlowOptions: ProcessFlowModel[] = [];

  loadingProcesses = false;

  prospectProcesses: ProcessModel[] = [];

  async mounted(): Promise<void> {
    this.loadItems();

    const loader = this.$loading.show();

    await this.loadProcessFlows();

    loader.hide();
  }

  async onCellClick(event: IGridCellEvent<ProcessModel>): Promise<void> {
    if (!event.data) {
      return;
    }

    const isProspectType = this.clientType === ClientTypeEnum.Prospect;
    const clientId = isProspectType ? this.activeClient.codCliente : this.activeClient.cnpjCpf;

    this.dialogConfig.processKanban.flow = this.processFlowOptions.find((x) => x.id === event.data.idFluxo);
    this.dialogConfig.processKanban.process = event.data;
    this.idFila = event.data.idFila;
    this.dialogConfig.processKanban.clientId = clientId;
    this.dialogConfig.processKanban.clientType = this.clientType;
    this.dialogConfig.processKanban.show = true;
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ProcessModel[]): void {
    this.selected = selected;
  }

  onFilterChange(type: string): void {
    if (type === 'multiple' && !this.multipleFilterChanged) {
      return;
    }

    this.multipleFilterChanged = false;
    this.loadItems();
  }

  onExport(selected: ProcessModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Processos',
        fileName: ProcessService.generateProcessExportFilename(new Date()),
      });
    }
  }

  onAddProcess(flow: ProcessFlowModel): void {
    this.dialogConfig.processKanban.flow = flow;
    this.dialogConfig.processKanban.clientId = this.activeClient.codCliente;
    this.dialogConfig.processKanban.clientType = this.clientType;
    this.dialogConfig.processKanban.show = true;
  }

  onAfterKanbanClose(): void {
    this.dialogConfig.processKanban.process = null;
    this.loadItems();
  }

  get viewTitle(): string {
    return this.$t('crm.view.process.title', { clientType: this.$t(`crm.${this.clientType}`) }).toString();
  }

  get filteredItems(): ProcessModel[] {
    let filteredItems = this.items;

    if (this.filters.flow && this.filters.flow.length > 0) {
      filteredItems = this.items.filter((item) => this.filters.flow.filter((flow) => item.idFluxo === flow.id)
        .length > 0);
    }

    if (this.filters.keyword) {
      const columnsToSearch = ['idProcesso', 'fluxo', 'etapa', 'data', 'fila', 'acao', 'motivo', 'resultado'];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }

    return filteredItems;
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  get processKanbanDialogTitle(): string {
    const flow = this.dialogConfig.processKanban.flow as ProcessFlowModel;
    return `${this.$t('crm.view.dashboard.dialog.processKanban.title', { flow: flow.nome })}`;
  }

  get isConvertedProspect(): boolean {
    return this.activeClient.situacao === ClientStatusEnum.Converted;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    const clientId = this.getClientIdFromRoute();
    const listOpenProcessesOnly = !(this.clientType === ClientTypeEnum.Prospect && this.activeClient.codCliente);

    this.items = await this.processService.getProcesses(clientId, this.clientType, 0, listOpenProcessesOnly);
    this.gridSettings.loading = false;
  }

  private async loadProcessFlows(): Promise<void> {
    this.processFlowOptions = await this.processService.getFlows();
  }

  private getClientIdFromRoute(): string {
    if (this.clientType === ClientTypeEnum.Prospect) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }

  private async loadProspectProcesses(): Promise<void> {
    this.dialogConfig.prospectProcesses.show = true;
    if (this.prospectProcesses.length === 0) {
      this.loadingProcesses = true;
      try {
        this.prospectProcesses = await this.processService.getProcesses(
          this.activeClient.prospectId ? this.activeClient.prospectId?.toString() : '',
          ClientTypeEnum.Prospect,
          0,
          false,
        );
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.loadingProcesses = false;
      }
    }
  }
}
